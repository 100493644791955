/**
 * This plug-in is for DataTables. It is a custom plugin for sorting the loan statuses
 */
jQuery.extend( jQuery.fn.dataTableExt.oSort, {

    "fundu-status-pre": function ( d ) {
        switch (d) {
            case 'Active':
                return 10;
            case 'Overdue':
                return 20;
            case 'Closed':
                return 30;

            case 'ActiveShare':
                return 40;
            case 'PendingShare':
                return 50;
            case 'SuccessfulShare':
                return 60;
            case 'CancelledShare':
                return 70;
        }
        return 0;
    }
});
