/**
 * This plug-in is for DataTables. It is a custom plugin for sorting the currency amounts
 * Examples:
 *   + 0.17 €
 *   - 50.00 €
 *   + 1 000.00 €
 */
jQuery.extend( jQuery.fn.dataTableExt.oSort, {

    "fundu-currency-pre": function ( d ) {
        // Assumes last character to be currency symbol
        return parseFloat(d.replace(/\s/g, '').slice(0, -1));
    }
});
