/**
 * This plug-in is for DataTables. It is a cusom plugin for sorting the due dates
 * Cases:
 *   Not available: - or N/A
 *   Paid: Maksettu or Paid
 *   Due date: Date in (DD.MM.YYYY) format
 *   Due date with info: Date in (DD.MM.YYYY) format followed by string data
 */
jQuery.extend( jQuery.fn.dataTableExt.oSort, {

    "fundu-duedate-pre": function ( d ) {
        if(d === Msg('common.state.paid')){
            return 0;
        }

        var value = d.replace(/\s+/g,' ').trim().match(/(([0-9]{2}\.){2}[0-9]{4})/gi);

		if(value === '' || value === null){
            return -1;
        }
        else{
            return parseInt( moment( value[0], 'DD.MM.YYYY', true ).format( 'x' ), 10 );
        }
    }
});
